import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Make Up Day.`}</em></p>
    <p><strong parentName="p">{`*`}{`The gym will be closed today for the Winter Throwdown. If you aren’t
competing or volunteering come out and cheer on the competitors!  The
first heat starts at 9:00am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      